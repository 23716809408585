import moment from 'moment/moment'

export const DATE_MANUAL_ENTRY_FORMAT = 'D.M.YYYY'
export const DATE_PICKER_FORMAT = 'YYYY-MM-DD'

export function formatDate(date) {
  if (!date) return null
  const [year, month, day] = date.split('-')

  const formattedDay = day.includes('T') ? day.split('T')[0] : day
  return `${formattedDay}.${month}.${year}`
}

export function parseDate(date) {
  if (!date) return null

  const [month, day, year] = date.split('/')
  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
}

export function parseDateFromManualEntry(date) {
  if (!date) return null
  const parsedDate = moment(date, DATE_MANUAL_ENTRY_FORMAT, true)

  if (! parsedDate.isValid()) return null

  return parsedDate.format(DATE_PICKER_FORMAT)
}
